import styled from "styled-components";
import { orange } from "../../bosons/colors";

export const AboutContainer = styled.div`
  background: ${orange.light};
  display: flex;
  margin-top: 120px;
  justify-content: center;
  position: relative;
`;

export const AboutContentContainer = styled.div`
  display: flex;
  max-width: 800px;
  margin-left: -62px;

  @media (max-width: 768px) {
    margin-left: 0;
    flex-direction: column;
  }
`;

export const AboutImage = styled.img`
  max-width: 300px;
  width: auto;
  margin-top: -64px;
  margin-bottom: 32px;
  border: 12px solid ${orange.light};
  border-radius: 20px;
  pointer-events: none;

  @media (max-width: 768px) {
    margin: -64px auto 0;
  }
`;
export const AboutContent = styled.div`
  padding: 24px;
`;

export const AboutTitle = styled.h2`
  margin-top: -48px;
  font-size: 48px;
  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 32px;
  }
`;

export const AboutText = styled.p`
  font-size: 16px;
  line-height: 32px;
`;

export const AboutCEOName = styled.p`
  font-weight: 600;
  line-height: 0;
  margin-top: 50px;
  display: block;
`;
export const AboutCEODescription = styled.p`
  font-size: 12px;
  line-height: 0;
`;

export const AboutFigure = styled.img`
  position: absolute;
  top: -180px;
  right: 0;
  width: 450px;
  pointer-events: none;
  @media (max-width: 768px) {
    display: none;
  }
`;
