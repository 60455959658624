import React from "react";
import {
  HeroContainer,
  HeroContent,
  HeroLogo,
  HeroBg,
  HeroButton,
  HeroSteps,
  HeroStep,
  HeroStepNumber,
  HeroStepImage,
  HeroStepText,
} from "./styles";
import Logo from "../../../assets/signed-logo.webp";
import Bike from "../../../assets/bike.webp";
import Cart from "../../../assets/cart.webp";
import Time from "../../../assets/time.webp";

import { steps } from "./data.json";

const stepsIcons = {
  1: Cart,
  2: Time,
  3: Bike,
};

function App() {
  return (
    <HeroContainer>
      <HeroContent>
        <h1>
          <HeroLogo src={Logo} alt="Genti" />
        </h1>
        <p>
          grandes momentos pedem por grandes celebrações. por isso, genti
          oferece a experiência de presentear com gentileza em forma de caixa.
          algo tão especial que não cabe em uma cesta de café da manhã.
          encomende e agende para a data e horário perfeitos para surpreender
          alguém especial! atendemos por delivery em toda{" "}
          <b>Belo Horizonte e região metropolitana</b>
        </p>
        <HeroSteps>
          {steps.map((step) => (
            <HeroStep key={step.number}>
              <HeroStepNumber>{step.number}</HeroStepNumber>
              <HeroStepImage src={stepsIcons[step.number]} alt={step.number} />
              <HeroStepText>{step.text}</HeroStepText>
            </HeroStep>
          ))}
        </HeroSteps>
      </HeroContent>
      <HeroBg />
    </HeroContainer>
  );
}

export default App;
