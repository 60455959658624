import React from "react";
import Hero from "../../organisms/Hero";
import Products from "../../organisms/Products";
import About from "../../organisms/About";
import Main from "../../templates/Main";
import { HomeContainer, ProductsTitle } from "./styles";
import Granola from "../../organisms/Granola";

function App() {
  return (
    <Main
      title="Genti - Caixas para presentear"
      description="grandes momentos exigem grandes celebrações, encomende agora caixas
    para presentear entregues na data e na hora perfeita em toda Belo
    Horizonte e região metropolitana"
      canonical="https://somosgenti.com.br/"
    >
      <HomeContainer>
        <Hero />
        <ProductsTitle>nossas caixas</ProductsTitle>
        <Products />
        <ProductsTitle>nossa granola</ProductsTitle>
        <Granola />
        <About />
      </HomeContainer>
    </Main>
  );
}

export default App;
