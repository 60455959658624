import React from "react";
import {
  AboutContainer,
  AboutContentContainer,
  AboutImage,
  AboutTitle,
  AboutText,
  AboutContent,
  AboutCEOName,
  AboutCEODescription,
  AboutFigure,
} from "./styles";

import AboutGenti from "../../../assets/mariana.webp";
import GentiFigure from "../../../assets/genti-ilustra.webp";

function About() {
  return (
    <AboutContainer>
      <AboutContentContainer>
        <AboutImage src={AboutGenti} alt="Mariana Nunes - fundadora do genti" />
        <AboutContent>
          <AboutTitle>quem somos</AboutTitle>
          <AboutText>
            <b>genti</b> é um abraço em forma de caixa. é um presente de amor,
            de amizade, de parceria, um “obrigada por tudo”, um “te apoio e
            estou com você”.
          </AboutText>
          <AboutText>
            é a lembrança e a vontade de estar alí comemorando um aniversário, o
            reconhecimento de uma conquista, um sorriso entregue à distância.{" "}
            <b>é a gentileza que só quem é genti tem</b>.
          </AboutText>
          <AboutCEOName>Mariana Nunes</AboutCEOName>
          <AboutCEODescription>fundadora do genti</AboutCEODescription>
        </AboutContent>
      </AboutContentContainer>
      <AboutFigure src={GentiFigure} alt="Ilustração de alecrim" />
    </AboutContainer>
  );
}

export default About;
