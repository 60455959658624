import React from "react";
import {
  ProductsContainer,
  ProductCard,
  ProductName,
  ProductItems,
  ProductItem,
  ProductOffers,
  ProductOffer,
  ProductOfferImage,
  ProductOfferName,
  ProductOfferPriceLabel,
  ProductOfferPrice,
  ProductCardHeader,
  ProductCardFooter,
  ProducHeaderImgContainer,
  ProductDesc,
  ProductIngredients,
} from "./styles";

import GranolaImage from "../../../assets/granola.webp";

import { product } from "./data.json";

function Granola() {
  return (
    <ProductsContainer>
      <ProductCard key={product.number}>
        <ProductCardHeader>
          <ProducHeaderImgContainer>
            <ProductName>{product.name}</ProductName>
            <ProductOfferImage
              src={GranolaImage}
              alt={product.name}
            />
          </ProducHeaderImgContainer>
          <ProductDesc>{product.description}</ProductDesc>
          <ProductIngredients>Ingredientes:</ProductIngredients>
          <ProductItems>
            <ProductItem>{product.items}</ProductItem>
          </ProductItems>
        </ProductCardHeader>
      </ProductCard>
    </ProductsContainer>
  );
}

export default Granola;
