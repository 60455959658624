import styled from "styled-components";
import BackgroundGenti from "../../../assets/genti-paes.webp";
import { orange, white, pink } from "../../bosons/colors";

export const HeroContainer = styled.div`
  display: flex;
`;

export const HeroLogo = styled.img`
  display: block;
  width: 220px;
  margin-top: 0px;

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 120px;
  }
`;

export const HeroContent = styled.div`
  > h1 {
    margin: 0;
  }
  > p {
    font-size: 16px;
    max-width: 80%;
    margin-top: 24px;
  }
  width: 50%;
  box-sizing: border-box;
  padding: 24px;
  font-size: 18px;
  line-height: 32px;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding: 24px;
    > p {
      max-width: 100%;
      font-size: 14px;
      line-height: 20px;
      margin-top: 24px;
    }
  }
`;

export const HeroBg = styled.div`
  width: 60%;
  height: auto;
  position: relative;
  top: 0;
  right: 0;
  overflow: hidden;
  background: url(${BackgroundGenti}) center center;
  background-size: cover;
  z-index: -1;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeroButton = styled.a`
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 24px;
  text-decoration: none;
  margin-top: 24px;
  border: 4px solid ${orange.dark};
  transition: background 0.3s ease, color 0.3s ease;
  background: ${orange.dark};
  color: ${white.default};

  &:hover {
    background: ${white.default};
    color: ${orange.dark};
  }
  @media (max-width: 600px) {
    margin-top: 8px;
    padding: 16px;
  }
`;

export const HeroSteps = styled.div`
  display: flex;
  margin: 50px 0 24px;
  @media (max-width: 600px) {
    flex-direction: column;
    margin: 8px 0;
  }
`;

export const HeroStep = styled.div`
  text-align: center;
  width: 200px;
  border: 2px solid ${pink.default};
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: row;
    border: 0;
    border: 1px solid ${pink.default};
    margin: 8px 0;
    align-items: center;
    text-align: left;
    justify-content: center;
    border-radius: 5px;
  }
`;

export const HeroStepNumber = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: ${pink.default};
  background: ${white.default};
  transform: translateY(-14px);
  padding: 0 12px;
  @media (max-width: 600px) {
    transform: none;
  }
`;

export const HeroStepText = styled.p`
  font-size: 14px;
  padding: 8px;
  line-height: 18px;
  margin-bottom: 8px;
`;

export const HeroStepImage = styled.img`
  width: 80px;
  margin-top: -8px;
  @media (max-width: 600px) {
    margin-top: 0;
    width: 60px;
    margin-right: 4px;
  }
`;
