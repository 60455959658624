/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { pink, red, white } from "../../bosons/colors";

export const HomeContainer = styled.div``;

export const ProductsTitle = styled.h2`
  font-size: 48px;
  color: ${pink.dark};
  font-weight: 600;
  margin: 24px 0 32px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
