import React from "react";
import {
  ProductsContainer,
  ProductCard,
  ProductName,
  ProductItems,
  ProductItem,
  ProductOffers,
  ProductOffer,
  ProductOfferImage,
  ProductOfferName,
  ProductOfferPriceLabel,
  ProductOfferPrice,
  ProductCardHeader,
  ProductCardFooter,
  ProducHeaderImgContainer,
} from "./styles";

import Caixa1 from "../../../assets/caixa1.webp";
import Caixa2 from "../../../assets/caixa2.webp";
import Caixa3 from "../../../assets/caixa3.webp";
import Caixa4 from "../../../assets/caixa4.webp";

import { products } from "./data.json";

const offerImages = {
  1: Caixa1,
  2: Caixa2,
  3: Caixa3,
  4: Caixa4,
};

function Products() {
  return (
    <ProductsContainer>
      {products.map((product) => (
        <ProductCard key={product.number}>
          <ProductCardHeader>
            <ProducHeaderImgContainer>
              <ProductName>{product.name}</ProductName>
              <ProductOfferImage
                src={offerImages[product.number]}
                alt={product.name}
              />
            </ProducHeaderImgContainer>
            <ProductItems>
              {product.items.map((item) => (
                <ProductItem key={item}>{item}</ProductItem>
              ))}
            </ProductItems>
          </ProductCardHeader>
        </ProductCard>
      ))}
    </ProductsContainer>
  );
}

export default Products;
