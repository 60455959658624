import styled from "styled-components";
import { pink } from "../../bosons/colors";

export const ProductsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 24px 0 48px;
  padding: 0 12px;

  @media (max-width: 768px) {
    margin: 0 0 64px;
  }
`;

export const ProductCard = styled.div`
  position: relative;
  width: calc(25% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid ${pink.default};

  @media (max-width: 768px) {
    width: calc(50% - 24px);
    margin-bottom: 24px;
  }
  @media (max-width: 500px) {
    width: calc(100% - 24px);
    margin-bottom: 24px;
  }
`;

export const ProductCardHeader = styled.div``;
export const ProductCardFooter = styled.div``;

export const ProducHeaderImgContainer = styled.div`
  position: relative;
`;
export const ProductName = styled.h3`
  color: white;
  display: inline-block;
  position: absolute;
  bottom: -4px;
  right: 16px;
  margin: 0;
  font-size: 32px;
`;
export const ProductItems = styled.ul`
  margin: 16px;
  padding: 0;
`;
export const ProductItem = styled.li`
  list-style: none;
  margin: 8px 0;
  font-size: 14px;
`;

export const ProductOfferImage = styled.img`
  width: 100%;
  overflow: hidden;
`;
export const ProductOffers = styled.div`
  display: flex;
`;
export const ProductOffer = styled.a`
  display: block;
  width: 50%;
  text-align: center;
  border: 2px solid ${pink.default};
  margin: -2px;
  padding: 16px 0;
  cursor: pointer;
  color: #333;
  text-decoration: none;

  &:hover {
    color: white;
    background: ${pink.default};
  }

  &:first-child {
    border-right: 1px solid ${pink.default};
    margin-right: 0;
  }

  &:last-child {
    margin-left: 0;
    border-left: 1px solid ${pink.default};
  }
`;
export const ProductOfferName = styled.span`
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
`;
export const ProductOfferPriceLabel = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
  opacity: 0.7;
`;
export const ProductOfferPrice = styled.span`
  font-weight: 600;
  font-size: 14px;
`;
